import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Loading from '../views/Loading.vue'

Vue.use(VueRouter)

/****
version = 1 // ecar
version = 2 //
version = 3 // pos retail
version = 4 // crm
version = 5 // invoicelist
version = 6 // POS
 ****/

// const version = 1;

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Loading',
    component: Loading
  }, 
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: Home
  // },
  {
    path: '/home',
    name: 'Home',
    meta: { transition: 'slide-left' },
    component: () => import( '@/views/invoicesList.vue')
  },
  {
    path: '/invoicelist',
    name: 'InvoiceList',
    component: () => import( '@/views/invoicesList.vue')
  },
  {
    path: '/clientList',
    name: 'clientList',
    component: () => import( '@/views/clientList.vue')
  },
  {
    path: '/car-report',
    name: 'car-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/car-report.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: { transition: 'slide-left' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Logout.vue')
  },
  {
    path: '/inventory/purchase',
    name: 'Purchase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Purchase.vue')
  },
  {
    path: '/settings/system-settings',
    name: 'SYSTEM SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/system-settings.vue')
  },
  {
    path: '/settings/users-settings',
    name: 'USERSSETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/users-settings.vue')
  },
  {
    path: '/reports/vat-reports',
    name: 'vatreport',
    component: () => import( '@/views/vat-reports.vue')
  },
]


// if(version == 1){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: Home
//     })
// }
// if(version == 2){
//   routes.push(
//     {
//       path: '/home1',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: Home
//     })
// }
// if(version == 3){
//   routes.push(
//     {
//       path: '/home1',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/POS-RETAIL.vue')
//     })
// }
// if(version == 4){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/CustomerList.vue')
//     })
// }
// if(version == 5){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/invoicesList.vue')
//     })
// }
// else if(version == 6){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/POS.vue')
//     })
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
